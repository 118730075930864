<template>
  <section class="container">
    <section class="personal__center">
    <div class="personal__wrap inner">
      <div class="personal__menu">
        <h3 class="personal__title">个人中心</h3>
        <ul class="personal__li">
          <li @click="slideTab(0)" :class="{'active': activeIndex == 0}">我的申请</li>
          <li @click="slideTab(1)" :class="{'active': activeIndex == 1}">修改密码</li>
          <li @click="logout()">安全退出</li>
        </ul>
      </div>
      <div class="personal__right">
        <div class="personal__item" v-if="activeIndex == 0">
          <h2 class="personal__subtts"><strong>我的申请</strong></h2>
          <div class="my__apply">
            <div class="apply__head">
              <span>申请岗位</span>
              <span>申请时间</span>
              <span>状态</span>
              <span>操作</span>
            </div>
            <div class="apply__body">
              <ul class="apply__body--li">
                <li v-for="(item, index) in applyDatas" :key="index">
                  <div class="apply__fix" >
                    <span @click="Popshow(item.job_id)">{{item.title}}</span>
                    <span>{{item.create_time}}</span>
                    <span>{{item.status_text}}</span>
                    <div class="apply__edite">
                      <a href="jvascript:;" @click="deleteData(item.id)">撤销</a>
                      <router-link :to="`/edit/${item.id}`">编辑</router-link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="personal__item" v-if="activeIndex == 1">
          <h2 class="personal__subtts"><strong>修改密码</strong></h2>
          <div class="modify__password">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
              <el-form-item prop="oldpassword">
                <div class="modify__name"><i>*</i>原密码：</div>
                <el-input type="password" placeholder="请输入原始密码" v-model="ruleForm.oldpassword"></el-input>
              </el-form-item>
              <el-form-item prop="pwd">
                <div class="modify__name"><i>*</i>设置新密码：</div>
                <el-input type="password" placeholder="密码由8-20位字母，数字组成" v-model="ruleForm.pwd"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <div class="modify__name"><i>*</i>确认密码：</div>
                <el-input type="password" placeholder="请再次输入密码" v-model="ruleForm.password"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="modify__sub" @click="submitForm('ruleForm')">确认修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <PopRecuite :popData='popData'/>
  </section>
    <Footer/>
  </section>
</template>

<script>
import { useMyStore } from '../store';
import Footer from '../components/Footer';
import PopRecuite from '../components/common/PopRcuite.vue';
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else {
        if (this.ruleForm.password !== '') {
          this.$refs.ruleForm.validateField('password');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };

    var validatePassword = (rule, value, callback) => {
      if (!/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*.])[\da-zA-Z~!@#$%^&*.]{8,}$/.test(value)) {
        callback(new Error('密码必须包含至少一个字母和一个数字和一个特殊符号'))
      } else {
        callback()
      }
    };
    return {
      activeIndex: 0,
      ruleForm: {
        oldpassword: '',
        pwd: '',
        password: ''
      },
      rules: {  
        oldpassword: [
          { required: true, message: '请输入密码', trigger: ['change','blur']},
          { min: 8, max: 20, message: '密码长度在8到20个字符', trigger: ['blur', 'change'] },
          { validator: validatePassword, message: '密码必须包含至少一个字母和一个数字和一个特殊符号!', trigger: ['blur', 'change'] }
        ],
        pwd: [
          { validator: validatePass, trigger: 'blur' },
        ],
        password: [
          { min: 8, max: 20, message: '密码长度在8到20个字符', trigger: ['blur', 'change'] },
          { validator: validatePass2, trigger: 'blur' },
          { validator: validatePassword, message: '密码必须包含至少一个字母和一个数字和一个特殊符号!', trigger: ['blur', 'change'] }
        ]
      },
      popData: {},
      applyDatas: []
    }
  },
  computed: {
    isLoggedIn() {
      return localStorage.getItem('token'); 
    }
  },
  components: {
    Footer,
    PopRecuite
  },
  methods: {
    slideTab(index) {
      this.activeIndex = index
    },
    async applyData() {
      const {data: res} = await this.$http.get("resumeList");
      console.log(res)
      this.applyDatas = res.data.list
    },
    async getRecruitPop(id){
      const {data: res} = await this.$http.get(`jobView?id=${id}`);
      console.log(res)
      if(res.status == 1){
          this.popData = res.data;
      }else{
          this.$message.error('数据请求失败');
      }
    },
    async deleteData(id) {
      const {data: res} = await this.$http.get(`resumeRevoke?id=${id}`);
      console.log(res)
      if(res.status == 1){
        this.$message({
            message: res.info,
            type: 'success'
        });
        this.applyData()
      }else{
        this.$message.error(res.info);
      }
    },
    Popshow(id) {
      console.log(id)
        useMyStore().setPoprecuiteShow()
        this.getRecruitPop(id);
        $('#recuite__pops').animate({
            scrollTop: 0
        }, 0);
    },
    
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const {data: res} = await this.$http.post("editPwd", this.ruleForm);
          if(res.status == 0){
            this.$message.error(res.info);
          }else{
            this.$message({
              message: res.info,
              type: 'success'
            });
            this.$router.push('/login')
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async logout() {
      const {data: res} = await this.$http.post("logout");
      
      if(res.status == 0){
        this.$message.error(res.info);
      }else{
        this.$message({
          message: res.info,
          type: 'success'
        });
        localStorage.setItem('token', ''); 
        useMyStore().hideToken();
        if(localStorage.getItem('token') == null || localStorage.getItem('token') == ''){
          this.$router.push('/login')
        }
      }
    }
  },
  mounted() {
    if(localStorage.getItem('token') == null || localStorage.getItem('token') == ''){
      this.$router.push('/login')
    }
    this.applyData();
  }
}
</script>

<style>

</style>